<template>
  <CRow v-if="classInfo">
    <CCol md="12">
      <CCard>
        <CCardHeader class="bg-secondary h5"
          ><i class="ri-printer-line ri-lg"></i>&nbsp สรุปนักเรียนขาดเรียนรายวัน
          จำแนกตามห้อง</CCardHeader
        >
        <CCardBody>
          <CRow>
            <CCol lg="6">
              <div class="relative w-full mb-3" v-if="userData">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  วันที่:
                </label>
                <datepicker
                  name="d"
                  input-class="form-control bg-white"
                  format="yyyy-MM-dd"
                  placeholder="คลิ๊ก"
                  v-model="d"
                ></datepicker>
              </div>
            </CCol>

            <CCol lg="6">
              <SltLevel />
              <div class="text-right">
                <button class="btn btn-yellow" @click="getReport">ค้นหา</button>
              </div>
            </CCol>
            <CCol lg="12" v-if="missingInfo">
              <table class="table" style="margin-top: 20px" id="single-info">
                <tbody>
                  <tr>
                    <td colspan="8">
                      <div class="text-center">
                        <h5>โรงเรียน{{ userData.school_name }}</h5>
                        <h3>
                          สรุปรายชื่อนักเรียนชั้น
                          {{ $store.state.selectedLevel.label }}
                          ที่ขาดเรียนประจำวันที่ {{ dateConverter(this.d) }}
                          <br />รวม {{ totalMiss }} คน
                        </h3>
                      </div>
                    </td>
                  </tr>

                  <tr v-for="(stdClass, n) in missingInfo" :key="stdClass.id">
                    <td colspan="8">
                      <strong
                        >{{ $store.state.selectedLevel.label }}/{{n+1}}
                        มีนักเรียนขาดเรียนจำนวน {{ stdClass.length }} คน</strong
                      >
                      <span v-if="stdClass.length > 0"> ได้แก่:</span>
                      <div v-if="stdClass.length > 0">
                        <div
                          v-for="(std, o) in stdClass"
                          :key="std.id"
                          class="indent5"
                        >
                          {{ o + 1 }}) {{ std.stud_id }} {{ std.prefix_name
                          }}{{ std.std_name }} {{ std.std_surname }} จำนวน
                          {{ std.countMiss }} ครั้ง
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="8"></td>
                  </tr>
                  <tr>
                    <td colspan="5"></td>
                    <td colspan="3" width="20%">
                      <div class="text-center">
                        (..............................................)
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="5"></td>
                    <td colspan="3" width="20%">
                      <div class="text-center">ผู้รายงาน</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-right">
                <button class="btn btn-secondary" @click="printD">print</button>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import WidgetClassReport from "../widgets/WidgetClassReport";
import SltLevel from "../components/SltLevel";
import Datepicker from "vuejs-datepicker";
import { Printd } from "printd";

export default {
  name: "Colors",
  components: { WidgetClassReport, SltLevel, Datepicker },
  data() {
    return {
      nowYear: null,
      appointmantData: null,
      userData: this.$cookies.get("user"),
      levelInfo: null,
      classInfo: null,
      d: null,
      missingInfo: null,
      totalMiss: null,
      cssText: `
      @font-face {
  font-family: "Sarabun";
  src: local("Sarabun"), url(./assets/fonts/Sarabun-Light.ttf) format("truetype");
}
h1 h4 {
    color: black;
    font-family: Sarabun;
  }
  .text-red{
    color: red;
  }
.table th,
.table td {
    vertical-align: top;
    font-size: 0.7rem;
    font-family: Sarabun;
}
table{
  width:100% !important;
}
.sign tr{
  border: none !important;
}
.bg-red{
    background-color: #ef5550;
}
h4,
.h4 {
    font-size: 1.25rem
}
h5,
.h5 {
    font-size: 1.0rem
}
h3,
.h3 {
    font-size: 1.5rem;
    font-family: Sarabun
}
.text-right {
    text-align: right !important
}
.text-center {
    text-align: center !important;
    font-family: Sarabun;
}
.indent5 {
    text-indent: 5%;
}

`,
    };
  },
  beforeMount() {
    //FETCH CLASS DROPDOWN
    let formData = new FormData();
    formData.append("txtAction", "getAll");
    formData.append("schoolId", this.userData.user_address);
    this.axios
      .post(this.$hostUrl + "php_action/classAPI.php", formData)
      .then((response) => {
        this.classInfo = response.data.mainData;

        //console.log(response.data);
      });
  },
  methods: {
    getReport() {
      if (!this.d || this.d == "") {
        alert("กรุณาระบุวันที่");
        return false;
      }

      let formData = new FormData();
      formData.append("txtAction", "getMissByDateLevel");
      formData.append("schoolCode", this.userData.user_address);
      formData.append("d", this.dateConverter(this.d));
      formData.append("levelCode", this.$store.state.selectedLevel.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData)
        .then((response) => {
          this.missingInfo = response.data.mainData;
          this.totalMiss = response.data.totalMiss;
          console.log(response.data);
        });
    },
    dateConverter(dateInput) {
      let day = dateInput.getDate(); //Date of the month: 2 in our example
      let month = dateInput.getMonth() + 1; //Month of the Year: 0-based index, so 1 in our example
      let year = dateInput.getFullYear();
      return (
        year +
        "-" +
        String(month).padStart(2, "0") +
        "-" +
        String(day).padStart(2, "0")
      );
    },
    printD() {
      //const cssText = this.cssText;
      const cssText = this.cssText;
      const d = new Printd();
      d.print(document.getElementById("single-info"), [cssText]);
    },
  },
};
</script>
